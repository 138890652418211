:root {
  --background-color: #313131;
  --peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  --peer-border: 1px solid rgba(255, 255, 255, 0.15);
  --peer-empty-avatar: url("./images/buddy.svg");
  --peer-bg-color: #5daf94b5;
  --peer-video-bg-color: rgba(0, 0, 0, 0.75);
  --active-speaker-border-color: rgba(255, 255, 255, 1);
  --selected-peer-border-color: #3bb089;
}

html {
  height: 100%;
  width: 100%;
  font-family: "Roboto";
  font-weight: 300;
  margin: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  width: 100%;
  font-size: 16px;
  margin: 0;
}

#multiparty-meeting {
  height: 100%;
  width: 100%;
}

.MuiButton-containedSecondary {
  background-color: #3bb089 !important;
}

.MuiAppBar-colorPrimary {
  background-color: white !important;
  color: #313131 !important;
}

.MuiBadge-colorPrimary {
  background-color: #3bb089 !important;
}

.MuiFab-secondary {
  background-color: #3bb089 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3bb089 !important;
}

.MuiIconButton-colorSecondary {
  color: #3bb089 !important;
}
